(function($) {
  // Site title
  
  
  $( ".secondary-navigation-wrapper .container" ).append( $( ".current-menu-item .sub-menu, .current_page_parent .sub-menu" ) );


  $('.event-list li').each(function () {
	  var eventCat=$(this).find("div.cat-event");
	 $(this).find('.event-title').append($(eventCat).html()); 
	 console.log($(this).find('.event-title'));
  });
  
  $('[data-toggle="tooltip"]').tooltip(); 
	
	$('.event-list li').attr('id', function(i) {
		return 'event'+(i+1); 
	});

    
  function scrollToElement(selector, time, verticalOffset) {

		time = typeof(time) != 'undefined' ? time : 1000;
		verticalOffset = typeof(verticalOffset) != 'undefined' ? verticalOffset : 0;
		element = $(selector);
		offset = element.offset();
		offsetTop = offset.top + verticalOffset;
		$('html, body').animate({scrollTop: offsetTop}, parseInt(time), 'linear');
	}


  $('.secondary-navigation-wrapper a[href^=#]').click(function(e) {
    e.preventDefault();
    var target = $(this).attr('href');
    scrollToElement(target, 1000, 0);
  });

  wp.customize('blogname', function(value) {
    value.bind(function(to) {
      $('.brand').text(to);
    });
  });

})(jQuery);
